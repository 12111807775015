<template>
  <div>
    <b-alert
      variant="danger"
      :show="!loading && error ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ error }}
        </p>
      </div>
    </b-alert>

    <b-card
      v-if="!error"
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-dropdown
              text="Actions"
              variant="primary"
            >
              <b-dropdown-item
                :disabled="selectedRecords.length === 0"
                @click="deleteMultipleHandler"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="selectedRecords.length === 0"
                @click="reProcessMultipleHandler('extraction')"
              >
                <feather-icon icon="RefreshCcwIcon" />
                <span class="align-middle ml-50">Re-Process Extraction</span>
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="selectedRecords.length === 0"
                @click="reProcessMultipleHandler('email')"
              >
                <feather-icon icon="RefreshCwIcon" />
                <span class="align-middle ml-50">Re-Process Email</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <feather-icon
              v-if="isAdmin"
              v-b-tooltip.hover
              icon="FilterIcon"
              class="cursor-pointer mr-1"
              size="20"
              title="Filter Batches"
              @click.stop="filterBatches = true"
            />

            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>

      </div>

      <b-table-simple
        :class="{
          'table-busy': loading
        }"
        class="batches-table"
      >
        <colgroup>
          <col
            v-for="(tableColumn) of tableColumns"
            :key="tableColumn.key"
            :style="{ width: tableColumn.width + '%' }"
          >
        </colgroup>
        <b-thead>
          <b-tr>
            <template
              v-for="tableColumn of tableColumns"
            >
              <b-th
                v-if="tableColumn.key === 'select'"
                :key="tableColumn.key"
              >
                <b-form-checkbox
                  v-model="allRecordsSeleted"
                  :disabled="batches.length === 0"
                  @change="toggleRecordsSelection"
                />
              </b-th>

              <b-th
                v-if="tableColumn.key !== 'select' && tableColumn.sortable"
                :key="tableColumn.key"
                :aria-sort="sortBy === tableColumn.key ? sortDesc ? 'descending' : 'ascending' : 'none'"
                @click="customSort(tableColumn.key)"
              >
                {{ tableColumn.label }}
              </b-th>

              <b-th
                v-if="tableColumn.key !== 'select' && !tableColumn.sortable"
                :key="tableColumn.key"
              >
                {{ tableColumn.label }}
              </b-th>
            </template>
          </b-tr>
          <b-tr>
            <template
              v-for="tableColumn of tableColumns"
            >
              <b-th
                v-if="tableColumn.customSearch"
                :key="tableColumn.key"
              >
                <b-form
                  @submit.prevent="searchSubmitHandler"
                >
                  <b-form-input
                    v-model="searchBy[tableColumn.key]"
                    trim
                    :disabled="loading"
                    placeholder="Search"
                  />
                </b-form>
              </b-th>
              <b-th
                v-else
                :key="tableColumn.key"
              />
            </template>
          </b-tr>
        </b-thead>
        <b-tbody v-if="!loading">
          <b-tr
            v-for="(batch) of batches"
            :key="batch.id"
          >
            <b-td>
              <b-form-checkbox
                v-model="selectedRecords"
                :value="batch.id"
              />
            </b-td>
            <b-td>
              <b-link
                :to="{ name: 'batch', params: { id: batch.id } }"
                class="font-weight-bold d-block text-nowrap batch-link"
                :class="{
                  'text-secondary': batch.mode === 'uploading'
                }"
                :disabled="batch.mode === 'uploading'"
              >
                {{ batch.id }}
              </b-link>
            </b-td>
            <b-td>
              {{ batch.vendor }}
            </b-td>
            <b-td>{{ batch.type }}</b-td>
            <b-td>{{ batch.definition_id }}</b-td>
            <b-td>{{ batch.project }}</b-td>
            <b-td>{{ batch.extension }}</b-td>
            <b-td v-if="!trainingMode">
              {{ batch.mode }}
            </b-td>
            <b-td>
              {{ formatedDate(batch.created_at) }}
            </b-td>
            <b-td>
              <div class="text-nowrap">
                <div
                  class="d-inline"
                  @click="setTimelineId(batch.id)"
                >
                  <timeline
                    :batch-id="batch.id"
                    :icon-size="'18'"
                  />
                </div>

                <feather-icon
                  :id="`batch-row-${batch.id}-delete-icon`"
                  icon="TrashIcon"
                  class="mx-1 cursor-pointer"
                  size="18"
                  @click="deleteHandler(batch.id)"
                />
                <b-tooltip
                  title="Delete Batch"
                  class="cursor-pointer"
                  :target="`batch-row-${batch.id}-delete-icon`"
                  boundary="window"
                />
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div
        v-if="loading"
        class="text-center m-3 table-busy-spinner"
      >
        <b-spinner
          variant="primary"
        />
      </div>

      <div
        v-if="!loading && batches.length === 0"
        class="text-center m-3"
      >
        No records found!
      </div>

      <div
        v-if="!loading"
        class="mx-2 mt-1 mb-2"
      >
        <detailed-pagination
          :per-page="perPage"
          :current-page="currentPage"
          :total-records="totalRecords"
          :local-records="batches.length"
          @page-changed="pageChanged"
        />
      </div>

    </b-card>

    <delete-batch
      v-if="deleteBatches.length > 0"
      :ids="deleteBatches"
      @modal-closed="deleteBatches = []"
      @deleted="fetchBatches"
    />

    <re-process-batch
      v-if="reProcessBatches.length > 0"
      :title="reProcessTitle"
      :message="reProcessMessage"
      :api-url="reProcessUrl"
      :ids="reProcessBatches"
      @modal-closed="reProcessBatches = []"
      @completed="fetchBatches"
    />

    <filter-options
      v-if="filterBatches"
      @modal-closed="filterBatches = false"
    />
  </div>
</template>

<script>

import axios from 'axios'
import moment from 'moment-timezone'
import {
  VBTooltip, BCard, BRow, BCol, BSpinner, BLink, BTooltip, BAlert, BFormCheckbox, BDropdown, BDropdownItem, BForm, BFormInput,
  BTableSimple, BThead, BTr, BTbody, BTh, BTd,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import bus from '@/bus'
import FilterOptions from '@/components/UI/FilterOptions.vue'
import DeleteBatch from './DeleteBatch.vue'
import ReProcessBatch from './ReProcessBatch.vue'
import Timeline from '../UI/Timeline/Timeline.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BSpinner,
    BLink,
    BTooltip,
    BAlert,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BForm,
    BFormInput,
    vSelect,
    DeleteBatch,
    Timeline,
    ReProcessBatch,
    BTableSimple,
    BThead,
    BTr,
    BTbody,
    BTh,
    BTd,
    DetailedPagination,
    FilterOptions,
  },
  props: {
    trainingMode: {
      type: Boolean,
      required: false,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      loading: true,
      error: null,
      currentPage: 1,
      perPage: 10,
      totalRecords: 0,
      perPageOptions: [10, 25, 50, 100],
      batches: [],
      deleteBatches: [],
      reProcessBatches: [],
      selectedRecords: [],
      allRecordsSeleted: false,
      sortBy: 'created_at',
      sortDesc: true,
      initialized: false,
      searchBy: {
        id: null,
        vendor: null,
        type: null,
        definition_id: null,
        mode: null,
      },
      filterBatches: false,
      reFetchBatches: false,
      reProcessUrl: '',
      reProcessTitle: '',
      reProcessMessage: '',
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },
    tableColumns() {
      let items = [
        { key: 'select', label: '', width: 2 },
        {
          key: 'id', label: 'ID', sortable: true, customSearch: true, width: 11,
        },
        {
          key: 'vendor', label: 'Customer', sortable: true, customSearch: true, width: 17,
        },
        {
          key: 'type', label: 'type', sortable: true, customSearch: true, width: 17,
        },
        {
          key: 'definition_id', label: 'Definition Profile ID', sortable: true, customSearch: true, width: 17,
        },
        {
          key: 'project', label: 'Project', sortable: true, customSearch: true, width: 9,
        },
        {
          key: 'extension', label: 'File Extention', sortable: true, customSearch: true, width: 6,
        },
      ]

      if (!this.trainingMode) {
        items.push({
          key: 'mode', label: 'Mode', sortable: true, customSearch: true, width: 6,
        })
      }

      items = items.concat([
        {
          key: 'created_at', label: 'Created At', sortable: true, width: 9,
        },
        { key: 'actions', label: 'Actions', width: 6 },
      ])

      return items
    },
    batchIds() {
      return this.batches.map(batch => batch.id)
    },
    stickyFilters() {
      return {
        searchBy: this.searchBy,
        perPage: this.perPage,
      }
    },
    selectedProjectCountries() {
      return this.$store.getters['auth/selectedProjectCountries']
    },
    filterBy() {
      return {
        project_countries: this.selectedProjectCountries.map(e => `${e.countryCode}_${e.project}`).join(','),
      }
    },
  },
  watch: {
    perPage() {
      if (this.initialized) {
        this.currentPage = 1
        this.fetchBatches()
      }
    },
    selectedRecords(newValue) {
      if (this.batches.length > 0 && newValue.length === this.batches.length) {
        this.allRecordsSeleted = true
      } else {
        this.allRecordsSeleted = false
      }
    },
    batches() {
      this.selectedRecords = this.selectedRecords.filter(id => {
        const index = this.batches.findIndex(doc => doc.id === id)
        return index !== -1
      })
    },
    stickyFilters: {
      handler() {
        localStorage.setItem('batches-filter', JSON.stringify(this.stickyFilters))
      },
      deep: true,
    },
    filterBy() {
      if (!this.loading) {
        this.fetchBatches()
      } else {
        this.reFetchBatches = true
      }
    },
    loading(newVal) {
      if (!newVal && this.reFetchBatches) {
        this.reFetchBatches = false
        this.fetchBatches()
      }
    },
  },
  created() {
    const currentPage = localStorage.getItem('home-last-active-page')

    if (currentPage) {
      this.currentPage = parseInt(currentPage, 10)
    }

    const batchesFilterData = localStorage.getItem('batches-filter')
    if (batchesFilterData) {
      const batchesFilter = JSON.parse(batchesFilterData)
      if (batchesFilter.searchBy) {
        this.searchBy = batchesFilter.searchBy
      }
      if (batchesFilter.perPage) {
        this.perPage = batchesFilter.perPage
      }
    }
    this.$nextTick(() => {
      this.initialized = true
    })

    this.fetchBatches()
  },
  destroyed() {
    localStorage.setItem('home-last-active-page', this.currentPage)
  },
  methods: {
    pageChanged(page) {
      this.currentPage = page
      this.fetchBatches()
    },
    searchSubmitHandler() {
      this.currentPage = 1
      this.fetchBatches()
    },
    customSort(sortBy) {
      const sortDesc = sortBy === this.sortBy ? !this.sortDesc : false
      this.sortBy = sortBy
      this.sortDesc = sortDesc
      this.fetchBatches()
    },
    fetchBatches() {
      this.loading = true
      const data = {
        ...this.filterBy,
      }
      const params = {
        page_size: this.perPage,
        page: this.currentPage,
        sort_by: this.sortBy,
        sort_desc: this.sortDesc,
        ...this.searchBy,
      }
      if (this.trainingMode) {
        params.mode = 'training'
      }
      axios.post('/batches/filter_list/', data, {
        params,
      })
        .then(res => {
          this.batches = res.data.results
          this.totalRecords = res.data.count
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          const errorResponse = error?.response
          if (errorResponse && errorResponse.status === 404 && this.currentPage > 1) {
            this.currentPage -= 1
            this.fetchBatches()
          } else {
            this.error = error?.response?.data?.detail || ' Error fetching batches'
          }
        })
    },
    formatedDate(dateString) {
      return moment.utc(dateString).tz('America/New_York').format('DD/MM/YYYY HH:mm')
    },
    deleteHandler(id) {
      this.deleteBatches = [id]
    },
    deleteMultipleHandler() {
      if (this.selectedRecords.length === 0) {
        return
      }
      this.deleteBatches = [...this.selectedRecords]
    },
    reProcessMultipleHandler(type) {
      if (this.selectedRecords.length === 0) {
        return
      }
      if (type === 'extraction') {
        this.reProcessUrl = '/pipeline/re_process_extraction/'
        this.reProcessTitle = 'Re-Process Extraction Batch'
        this.reProcessMessage = `Are you sure you want to re-process the following ${this.selectedRecords.length > 1 ? 'batches' : 'batch'} ?`
      } else if (type === 'email') {
        this.reProcessUrl = '/pipeline/re_process_training_batches/'
        this.reProcessTitle = 'Re-Process Email Batch'
        this.reProcessMessage = `The following ${this.selectedRecords.length > 1 ? 'batches' : 'batch'} will be deleted and regenerated. Are you sure? `
      }
      this.reProcessBatches = [...this.selectedRecords]
    },
    toggleRecordsSelection(checked) {
      this.selectedRecords = checked ? this.batches.map(doc => doc.id) : []
    },
    setTimelineId(batchID) {
      bus.$emit('setTimelineID', batchID)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.batch-link.disabled {
  pointer-events: none;
}
.table-busy {
  opacity: 0.55;
  pointer-events: none;
}
.table-busy-spinner {
 opacity: 0.55;
}
.batches-table {
  td {
    padding: 0.4rem 0.5rem;
    vertical-align: baseline;
  }
  th {
    padding: 0.8rem 0.5rem;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
