<template>
  <b-modal
    v-model="showModal"
    centered
    :title="title"
    @hidden="$emit('modal-closed')"
    @ok="confirmHandler"
  >
    <b-card-text>
      <div v-if="ids.length === 1">
        {{ message }} <span class="text-primary">{{ ids[0] }}</span>?
      </div>
      <div v-else>
        {{ message }}
        <div
          v-for="(id, index) of ids"
          :key="index"
          class="text-primary"
        >
          {{ id }}
        </div>
      </div>

    </b-card-text>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="isProcessing"
        @click="ok()"
      >
        Re-Process
        <b-spinner
          v-if="isProcessing"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios'
import {
  BModal, BCardText, BButton, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BCardText,
    BButton,
    BSpinner,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: 'Are you sure you want to re-process the following batches?',
    },
    ids: {
      type: Array,
      required: true,
    },
    templateName: {
      type: String,
      required: false,
      default: '',
    },
    apiUrl: {
      type: String,
      default: '/pipeline/re_process_extraction/',
    },
  },
  data() {
    return {
      showModal: true,
      isProcessing: false,
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  methods: {
    confirmHandler(event) {
      event.preventDefault()
      this.isProcessing = true

      const payload = {
        ids: this.ids,
      }
      if (this.currentRouteName === 'templates') {
        payload.template_name = this.templateName
      }

      axios.post(this.apiUrl, payload)
        .then(() => {
          this.$emit('completed')

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Batches Queued for re-processing',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.showModal = false
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.detail || 'Somthing went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          this.isProcessing = false
        })
    },
  },
}

</script>
