<template>
  <div>
    <h2>Transactions</h2>
    <Batches />
  </div>
</template>

<script>
import Batches from '@/components/Batches/Batches.vue'

export default {
  components: {
    Batches,
  },
}
</script>
