<template>
  <app-timeline-item
    :variant="apptimeColorVarient"
  >
    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-sm-0">
      <h6>{{ item.message }}</h6>
      <small class="text-muted">{{ formatedDate(item.event_time) }}</small>
    </div>
    <template v-if="item.action === 'download_output_json'">
      <b-button
        variant="primary"
        :disabled="loading"
        size="sm"
        @click="downloadJSON('output', '')"
      >
        Download Output JSON
        <b-spinner
          v-if="loading"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
    <template v-if="item.action === 'download_automated_table_model_json'">
      <b-button
        variant="primary"
        :disabled="loading"
        size="sm"
        @click="downloadJSON('automated_table_model', '')"
      >
        Download Automated Table Model JSON
        <b-spinner
          v-if="loading"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
    <template v-else-if="item.action === 'download_djson_excel'">
      <b-button
        variant="primary"
        :disabled="loading"
        size="sm"
        @click="downloadExcel()"
      >
        Download DataJson Excel
        <b-spinner
          v-if="loading"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
    <template v-else-if="item.action === 'download_definition_fields_json'">
      <b-button
        variant="primary"
        :disabled="loading"
        size="sm"
        @click="downloadJSON('definition_fields', item.remarks ? item.remarks.replace('filename:', ''): '')"
      >
        Download Definition Fields JSON
        <b-spinner
          v-if="loading"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
    <template v-else-if="item.action === 'display_subprocess_messages' || item.action === 'display_docbuilder_response'">
      <div>
        <li
          v-for="(logItem, index) of JSON.parse(item.remarks)"
          :key="index"
        >
          <b :class="{ 'text-warning': logItem.code !== 200, 'text-danger': logItem.message.includes('Fail') || item.remarks.includes('fail') }">{{ logItem.module }}</b> -
          <span style="white-space: pre-wrap">{{ logItem.message }}</span>
        </li>
      </div>
    </template>
    <template v-else-if="item.action === 'display_key_values'">
      <div>
        <li
          v-for="(value, key) of JSON.parse(item.remarks)"
          :key="key"
        >
          <b>{{ key }}</b> - {{ value }}
        </li>
      </div>
    </template>
    <template v-else-if="item.action === 'display_table'">
      <div>
        <b-table
          class="table"
          :items="JSON.parse(item.remarks)"
          :columns="JSON.parse(item.remarks)[0].keys"
          :small="true"
          :bordered="true"
        />
      </div>
    </template>
    <template v-else-if="item.action === 'display_json'">
      <div>
        <div v-if="item.sub_message">
          {{ item.sub_message }}
        </div>
        <a
          href="javascript:void(0)"
          @click="toogleDisplayJson"
        >
          {{ jsonLinkText }}
        </a> |
        <a
          href="javascript:void(0)"
          @click="downloadLocalJSON(JSON.parse(item.remarks))"
        >
          Download JSON
        </a>
        <b-spinner
          v-if="loading"
          small
          label="Small Spinner"
        />
      </div>
      <div
        v-if="jsonDisplayed"
      >
        <vue-json-pretty
          :data="JSON.parse(item.remarks)"
          :show-icon="true"
          :virtual="true"
        />
      </div>
    </template>
    <p v-else-if="item.remarks">
      {{ item.remarks }}
    </p>
  </app-timeline-item>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BSpinner, BButton, BTable } from 'bootstrap-vue'
import exportFromJSON from 'export-from-json'
import download from 'downloadjs'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  components: {
    AppTimelineItem,
    BSpinner,
    BButton,
    BTable,
    VueJsonPretty,
  },
  props: {
    batchId: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      jsonDisplayed: false,
      jsonLinkText: 'View JSON',
    }
  },
  computed: {
    apptimeColorVarient() {
      let color = 'success'
      if (this.item.status === 'failed') {
        color = 'danger'
      } else if (this.item.remarks && (this.item.remarks.includes('Fail') || this.item.remarks.includes('fail'))) {
        color = 'danger'
      } else if (this.item.status === 'warning') {
        color = 'warning'
      }
      return color
    },
  },
  methods: {
    formatedDate(dateString) {
      return moment.utc(dateString).tz('America/New_York').format('DD/MM/YYYY HH:mm')
    },
    downloadJSON(fileType, fileName) {
      this.loading = true
      const params = { batch_id: this.batchId, file_type: fileType, file_name: fileName }
      axios.get('/retrive_json/', { params })
        .then(res => {
          exportFromJSON({ data: res.data, fileName: `${fileType}-${this.batchId}`, exportType: 'json' })
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.res?.data?.detail || 'Failed to download requested data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    downloadExcel() {
      this.loading = true
      const params = { batch_id: this.batchId }
      axios.get('/download_djson_excel/', { params, responseType: 'blob' })
        .then(res => {
          const contentType = res.headers['content-type']
          download(res.data, `djson-${this.batchId}.xlsx`, contentType)
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.res?.data?.detail || 'Failed to download requested data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    toogleDisplayJson() {
      this.jsonDisplayed = !this.jsonDisplayed
      if (this.jsonDisplayed) {
        this.jsonLinkText = 'Hide JSON'
      } else {
        this.jsonLinkText = 'View JSON'
      }
    },
    downloadLocalJSON(data) {
      this.loading = true
      exportFromJSON({ data, fileName: 'AIDB_JSON', exportType: 'json' })
      this.loading = false
    },
  },
}
</script>
