<template>
  <div>
    <div class="d-flex justify-content-between align-items-center my-2">
      <h4 class="text-primary p-0 m-0">
        Timeline - {{ batchId }}
      </h4>
      <div class="mb-50">
        <feather-icon
          icon="XIcon"
          size="20"
          class="cursor-pointer"
          @click="$emit('close')"
        />
      </div>
    </div>

    <div
      v-if="loading"
      class="text-center my-1"
    >
      <b-spinner
        variant="primary"
        label="Small Spinner"
      />
    </div>

    <b-alert
      :variant="bAlertColor"
      :show="!loading && loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <template v-if="!loading && !loadingError">
      <div v-if="logs.length === 0">
        <p> No records to display </p>
      </div>

      <div
        v-if="logs.length > 0"
        class="timeline-content-div"
      >
        <app-timeline>
          <timeline-detail-item
            v-for="item in logs"
            :key="item.id"
            :batch-id="batchId"
            :item="item"
          />
        </app-timeline>
      </div>
    </template>
  </div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import { BSpinner, BAlert } from 'bootstrap-vue'
import TimelineDetailItem from '@/components/UI/Timeline/TimelineDetailItem.vue'

export default {
  components: {
    AppTimeline,
    BSpinner,
    TimelineDetailItem,
    BAlert,
  },
  props: {
    batchId: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    loadingError: {
      type: String,
      required: false,
      default: null,
    },
    logs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    bAlertColor() {
      return this.logs?.length <= 1 ? 'warning' : 'danger'
    },
  },
}
</script>

<style scoped>
.timeline-content-div {
    padding: 5px;
    max-height: 50vh;
    overflow-y: scroll;
}
</style>
